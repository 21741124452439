<template>
  <div
    class="container"
    v-title
    id="elevatorDis"
    :data-title="$t('i18n.maintenanceVolumeStatistics')"
  >
    <div id="outer-title">{{ $t("i18n.maintenanceVolumeStatistics") }}</div>
    <jl-chart :columns="option" name="option"></jl-chart>
  </div>
</template>

<script>
import { reactive, toRefs, getCurrentInstance } from "vue";
import { useI18n } from "vue-i18n";
import jlChart from "../../components/chart";
export default {
  name: "BillStatic",
  components: {
    jlChart,
  },
  setup(props, { emit }) {
    emit("public_header", true);
    const { proxy } = getCurrentInstance();
    const { t } = useI18n();
    const state = reactive({
      option: {
        title: t("i18n.elevatorDis"),
        type: "bar",
        xData: [],
        series: [
          {
            data: [],
            itemStyle: {
              normal: {
                color: function (params) {
                  var index = params.dataIndex;
                  var alternateNumber = 6;
                  var newColor = "";
                  var colorList = [
                    "#268DF1",
                    "#FA583E",
                    "#F69863",
                    "#AE72EF",
                    "#FCE38C",
                    "#89C075",
                  ];
                  if (index < alternateNumber) {
                    newColor = colorList[index];
                  } else {
                    var rowNumber = Math.floor(index / alternateNumber);
                    newColor = colorList[index - rowNumber * alternateNumber];
                  }
                  return newColor;
                },
              },
            },
          },
        ],
      },
    });

    const init = async () => {
      let { data } = await proxy.$api.maintenance.getBillStatic();
      data.map((item) => {
        state.option.series[0].data.push(item.value);
        state.option.xData.push(item.name);
      });
    };

    init();

    return {
      ...toRefs(state),
    };
  },
};
</script>

<style lang="scss"></style>
